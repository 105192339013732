/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Layout, Menu, message, Modal } from "antd";
import { updateUser } from "./actions";
import { useDispatch } from "react-redux";
import Routes from "./components/Routes.jsx";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getListOfRecords } from "./components/Utilities.jsx";
import { useIdleTimer } from "react-idle-timer";

const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;
const App = (props) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);

  const handleNavigatePage = (page) => {
    if (page === "/patientinformation") {
      localStorage.setItem("Id", "");
      localStorage.setItem("reportedby", "");
      props.history.push({
        pathname: "/consent",
        state: {},
      });
    } else {
      props.history.push(page);
    }
  };

  const handleGetUserDetails = async () => {
    if (userDetails.username === "") {
      let tokenid = localStorage.getItem("tokenid");
      if (tokenid !== null) {
        try {
          await getListOfRecords("getuserdetailsbytokenid", {
            params: {
              tokenid: tokenid,
            },
          }).then((res) => {
            if (res) {
              if (res.status === 1) {
                dispatch(
                  updateUser({
                    Id: res.userDetails.Id,
                    username: res.userDetails.username,
                    fullname: res.userDetails.fullname,
                    tokenid: res.userDetails.tokenid,
                    admin: res.userDetails.admin,
                    FinalizedResult: res.userDetails.FinalizedResult,
                    GenerateCdrs: res.userDetails.GenerateCdrs,
                    SetAsReported: res.userDetails.SetAsReported,
                    DeleteCif: res.userDetails.DeleteCif,
                  })
                );
                localStorage.removeItem("tokenid");
                localStorage.setItem("tokenid", res.userDetails.tokenid);
              } else {
                props.history.push("/login");
              }
            }
          });
        } catch (error) {
          message.error(error.message);
        }
      } else {
        props.history.push("/login");
      }
    }
  };
  const handleLogOut = () => {
    Modal.confirm({
      title: "Logout system now?",
      icon: <ExclamationCircleOutlined />,

      onOk() {
        dispatch(
          updateUser({
            Id: "",
            username: "",
            fullname: "",
            tokenid: "",
          })
        );
        localStorage.removeItem("tokenid");
        props.history.push("/login");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleChangePassword = () => {
    props.history.push({
      pathname: "changeuserpassword",
      state: {
        id: userDetails.Id,
      },
    });
  };

  const handleChangeUserProfile = () => {
    props.history.push({
      pathname: "changeuserprofile",
      state: {
        id: userDetails.Id,
      },
    });
  };
  const handleOnIdle = (event) => {
    dispatch(
      updateUser({
        Id: "",
        username: "",
        fullname: "",
        tokenid: "",
      })
    );
    localStorage.removeItem("tokenid");
    props.history.push("/login");
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  useEffect(() => {
    if (
      props.location.pathname.substring(1, 7) !== "result" &&
      props.location.pathname.substring(1, 12) !== "downloadcif" &&
      props.location.pathname.substring(1, 8) !== "consent" &&
      props.location.pathname.substring(1, 7) !== "qrcode"
    ) {
      handleGetUserDetails();
    }
  }, []);
  return (
    <Layout>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={["2"]}>
          {userDetails.username !== "" && (
            <>
              <Menu.Item key="keyHome" onClick={() => handleNavigatePage("/")}>
                Home
              </Menu.Item>

              <SubMenu key="SubMenu" title="File">
                <Menu.Item
                  key="keyReferringFacility"
                  onClick={() => handleNavigatePage("/referringfacility")}
                >
                  Referring Facility
                </Menu.Item>
                <Menu.Item
                  key="keyUsers"
                  onClick={() => handleNavigatePage("/users")}
                >
                  Users
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                key="keyCifList"
                onClick={() => handleNavigatePage("/ciflist")}
              >
                CIF List
              </Menu.Item>
              <Menu.Item
                key="keyCdrs"
                onClick={() => handleNavigatePage("/cdrs")}
              >
                CDRS
              </Menu.Item>
              <SubMenu
                style={{ float: "right" }}
                key="mnuUser"
                title={userDetails.username}
              >
                <Menu.Item key="keyLogOut" onClick={() => handleLogOut()}>
                  Logout
                </Menu.Item>

                <Menu.Item
                  key="keyProfile"
                  onClick={() => handleChangeUserProfile()}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                  key="keyChangePassword"
                  onClick={() => handleChangePassword()}
                >
                  Change Password
                </Menu.Item>
              </SubMenu>
            </>
          )}

          {userDetails.username === "" && (
            <>
              <Menu.Item
                key="keyPatientInformation"
                onClick={() => handleNavigatePage("/patientinformation")}
              >
                Create New CIF
              </Menu.Item>
              <Menu.Item
                style={{ float: "right" }}
                onClick={() => handleNavigatePage("/login")}
              >
                Login
              </Menu.Item>
            </>
          )}
        </Menu>
      </Header>
      <Content
        className="site-layout"
        style={{ padding: "0 50px", marginTop: 64 }}
      >
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 380 }}
        >
          <Routes />
        </div>
      </Content>
      <div>
        <Footer style={{ textAlign: "center" }}>
          eCif ©2020 Created by Comlogik Business Systems
        </Footer>

        <div></div>
      </div>
    </Layout>
  );
};

export default withRouter(App);
